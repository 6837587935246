import { Routes, Route } from "react-router-dom";
import FrontPage from "./components/FrontPage";
import "@fontsource/inter/400.css";
import "@fontsource/inter/500.css";
import "@fontsource/inter/600.css";
import "@fontsource/inter/700.css";
import "@fontsource/inter/800.css";
import "@fontsource/inter/900.css";

import "./App.css";

function App() {
  return (
    <Routes>
      <Route exact path="/" element={<FrontPage />} />
      <Route path="*" element={<FrontPage />} />
    </Routes>
  );
}

export default App;
