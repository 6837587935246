import React from "react";
import "../styles/dashboard.scss";

export default function FrontPage() {
  return (
    <div className="dashboard">
      <h1>Otto - lektier</h1>
      <div className="cards">
        <a
          className="card"
          href="https://www.digipuzzle.net/dk/skolespil/index.htm"
        >
          <div className="card-thumbnail"></div>
          <div className="card-title">Digipuzzle</div>
        </a>
        <a className="card" href="https://www.matematikfessor.dk/">
          <div className="card-thumbnail"></div>
          <div className="card-title">Matematikfessor</div>
        </a>
        <a className="card" href="https://ereolen.dk/">
          <div className="card-thumbnail"></div>
          <div className="card-title">Ereolen</div>
        </a>
        <a className="card" href="https://www.grammatip.com/">
          <div className="card-thumbnail"></div>
          <div className="card-title">Grammatip</div>
        </a>
      </div>
      <h1>Otto - TV</h1>
      <div className="cards">
        <a
          className="card"
          href="https://www.dr.dk/drtv/serie/spise-med-price_43537"
        >
          <div className="card-thumbnail">
            <img
              src="https://production-static.dr-massive.com/shain/v1/dataservice/ResizeImage/$value?Format=%27jpg%27&Quality=75&EntityType=%27Item%27&EntityId=%27323934%27&Width=480&Height=270&ImageId=%2741958605%27&ResizeAction=%27fill%27&HorizontalAlignment=%27center%27&VerticalAlignment=%27top%27"
              alt="spise med price"
            />
          </div>
          <div className="card-title">Spise med Price</div>
        </a>
      </div>
      <h1>Opskrifter</h1>
      <a
        className="card"
        href="https://www.dr.dk/mad/opskrift/kartoffel-burgerboller"
      >
        <div className="card-thumbnail">
          <img
            src="https://asset.dr.dk/imagescaler01/http://mad-recipe-pictures-dr-dk.s3.amazonaws.com/prod/recipe/kartoffel-burgerboller-1660722947.jpg"
            alt="spise med price burgerboller"
          />
        </div>
        <div className="card-title">Burgerboller</div>
      </a>
    </div>
  );
}
